import React from 'react';

const Copyright = () => {
  return (
  <div className='border-t border-white/20 lg:pt-10'>
    <div className='container mx-auto'>
      <div className="py-1 pb-10">
        <p className='text-center text-base font-baskerville'>Copyright &copy; 2024 Restoran Dubrovnik</p>
      </div>
    </div>
  </div>
  );
};

export default Copyright;
