import Galerija01 from './assets/img/gallery/restoran-dubrovnik-gallery-01.jpg'
import Galerija1 from './assets/img/gallery/restoran-dubrovnik-gallery-1.jpg'
import Galerija2 from './assets/img/gallery/restoran-dubrovnik-gallery-2.jpg'
import Galerija3 from './assets/img/gallery/restoran-dubrovnik-gallery-3.jpg'
import Galerija4 from './assets/img/gallery/restoran-dubrovnik-gallery-4.jpg'
import Galerija6 from './assets/img/gallery/restoran-dubrovnik-gallery-6.jpg'
import Galerija7 from './assets/img/gallery/restoran-dubrovnik-gallery-7.jpg'
import Galerija8 from './assets/img/gallery/restoran-dubrovnik-gallery-8.jpg'
import Galerija9 from './assets/img/gallery/restoran-dubrovnik-gallery-9.jpg'
import Galerija10 from './assets/img/gallery/restoran-dubrovnik-gallery-10.jpg'
import Galerija11 from './assets/img/gallery/restoran-dubrovnik-gallery-11.jpg'
import Galerija12 from './assets/img/gallery/restoran-dubrovnik-gallery-12.jpg'
import Galerija13 from './assets/img/gallery/restoran-dubrovnik-gallery-13.jpg'
import Galerija14 from './assets/img/gallery/restoran-dubrovnik-gallery-14.jpg'
import Galerija15 from './assets/img/gallery/restoran-dubrovnik-gallery-15.jpg'
import Galerija16 from './assets/img/gallery/restoran-dubrovnik-gallery-16.jpg'
import Galerija17 from './assets/img/gallery/restoran-dubrovnik-gallery-17.jpg'
import Galerija18 from './assets/img/gallery/restoran-dubrovnik-gallery-18.jpg'
import Galerija19 from './assets/img/gallery/restoran-dubrovnik-gallery-19.jpg'
import Galerija20 from './assets/img/gallery/restoran-dubrovnik-gallery-20.jpg'
import Galerija22 from './assets/img/gallery/restoran-dubrovnik-gallery-22.jpg'
import Galerija23 from './assets/img/gallery/restoran-dubrovnik-gallery-23.jpg'
import Galerija24 from './assets/img/gallery/restoran-dubrovnik-gallery-24.jpg'
import Galerija25 from './assets/img/gallery/restoran-dubrovnik-gallery-25.jpg'
import Galerija26 from './assets/img/gallery/restoran-dubrovnik-gallery-26.jpg'
import Galerija27 from './assets/img/gallery/restoran-dubrovnik-gallery-27.jpg'
import Galerija28 from './assets/img/gallery/restoran-dubrovnik-gallery-28.jpg'
import Galerija29 from './assets/img/gallery/restoran-dubrovnik-gallery-29.jpg'
import Galerija31 from './assets/img/gallery/restoran-dubrovnik-gallery-31.jpg'
import Galerija32 from './assets/img/gallery/restoran-dubrovnik-gallery-32.jpg'
import Galerija33 from './assets/img/gallery/restoran-dubrovnik-gallery-33.jpg'


export const slike = [
    {
      src: Galerija31,
      title: 'Restaurant Dubrovnik - Old Town - waiter getting table ready for guests, Restaurant Dubrovnik plate with logo on the bottom side',
      description: 'Restaurant Dubrovnik waiter getting table ready for guests, Restaurant Dubrovnik plate with logo on the bottom side',
    },
    {
      src: Galerija32,
      title: 'Restaurant Dubrovnik - Old Town - waiter getting table ready for guests, Restaurant Dubrovnik plate with logo on the bottom side',
      description: 'Restaurant Dubrovnik - Old Town -waiter getting table ready for guests, Restaurant Dubrovnik plate with logo on the bottom side',
    },
    {
      src: Galerija33,
      title: 'Restaurant Dubrovnik menu with logo and logo on the wall behind the menu - Restoran Dubrovnik u centru same jezgre, meni i logo od restorana u pozadini na zidu',
      description: 'Restaurant Dubrovnik menu with logo and logo on the wall behind the menu - Restoran Dubrovnik u centru same jezgre, meni i logo od restorana u pozadini na zidu',
    },
    {
      src: Galerija01,
      title: 'Restaurant Dubrovnik fine dining - table for guests with plates, custom made napkins with Restaurant Dubrovnik logo and wine glasses',
      description: 'Restaurant Dubrovnik - table for guests with plates, custom made napkins with Restaurant Dubrovnik logo and wine glasses',
    },
    {
      src: Galerija1,
      title: 'Restaurant Dubrovnik fine dining Food - one of main dishes, octopus with corn and truffles and sauce',
      description: 'Restaurant Dubrovnik Food - one of main dishes, octopus with corn and truffles and sauce',
    },
    {
      src: Galerija2,
      title: 'Restaurant Dubrovnik fine dining Food - one of main dishes / Jedno od glavnih jela u Restoranu Dubrovnik - Old Town',
      description: 'Restaurant Dubrovnik Food',
    },
    {
      src: Galerija3,
      title: 'Restaurant Dubrovnik fine dining - Old Town - main dish',
      description: 'Restaurant Dubrovnik - Old Town - main dish',
    },
    {
      src: Galerija4,
      title: 'Restaurant Dubrovnik fine dining - Old Town - main dish with cocktail',
      description: 'Restaurant Dubrovnik - Old Town - main dish with cocktail',
    },
    {
      src: Galerija6,
      title: 'Restaurant Dubrovnik fine dining in Old Town - table with wine glasses and modern candle',
      description: 'Restaurant Dubrovnik in Old Town - table with wine glasses and modern candle',
    },
    {
      src: Galerija27,
      title: 'Restaurant Dubrovnik fine dining - waiter holding glass of red wine',
      description: 'Restaurant Dubrovnik - waiter holding glass of red wine',
    },
    {
      src: Galerija28,
      title: 'Restaurant Dubrovnik (Old City) waiter pouring red wine in glasses',
      description: 'Restaurant Dubrovnik (Old City) waiter pouring red wine in glasses',
    },
    {
      src: Galerija29,
      title: 'Restaurant Dubrovnik fine dining - two glasses with white wines',
      description: 'Restaurant Dubrovnik fine dining - two glasses with white wines',
    },
    {
      src: Galerija19,
      title: 'Restaurant Dubrovnik - Restoran Dubrovnik - door opening of wine cellar',
      description: 'Restaurant Dubrovnik - Restoran Dubrovnik - door opening of wine cellar',
    },
    {
      src: Galerija22,
      title: 'Restaurant Dubrovnik fine dining - inside look of wine cellar, wooden shelves carrying bottles of wine',
      description: 'Restaurant Dubrovnik fine dining - inside look of wine cellar, wooden shelves carrying bottles of wine',
    },
    {
      src: Galerija23,
      title: 'Restaurant Dubrovnik award falstaff - restaurant guide 2019 with wine bottles on right side',
      description: 'Restaurant Dubrovnik award falstaff - restaurant guide 2019 with wine bottles on right side',
    },
    {
      src: Galerija7,
      title: 'Restaurant Dubrovnik terrace',
      description: 'Restaurant Dubrovnik terrace',
    },
    {
      src: Galerija8,
      title: 'Restaurant Dubrovnik terrace with waiters and guests',
      description: 'Restaurant Dubrovnik terrace with waiters and guests',
    },
    {
      src: Galerija9,
      title: 'Restaurant Dubrovnik terrace with waiters and guests - Predivna terasa Restorana Dubrovnik u Starom Gradu',
      description: 'Restaurant Dubrovnik terrace with waiters and guests - Predivna terasa Restorana Dubrovnik u Starom Gradu',
    },
    {
      src: Galerija10,
      title: 'Restaurant Dubrovnik tables ready for upcoming guests',
      description: 'Restaurant Dubrovnik tables ready for upcoming guests',
    },
    {
      src: Galerija11,
      title: 'Restaurant Dubrovnik tables ready for upcoming guests',
      description: 'Restaurant Dubrovnik tables ready for upcoming guests',
    },
    {
      src: Galerija12,
      title: 'Restaurant Dubrovnik - guests sitting at table looking at custom made Restaurant Dubrovnik menus',
      description: 'Restaurant Dubrovnik - guests sitting at table looking at custom made Restaurant Dubrovnik menus',
    },
    {
      src: Galerija13,
      title: 'Restaurant Dubrovnik table for two with glasses of wine and beautiful flowers',
      description: 'Restaurant Dubrovnik table for two with glasses of wine and beautiful flowers',
    },
    {
      src: Galerija14,
      title: 'Restaurant Dubrovnik terrace full with guests waiting for their orders, talking and enjoying',
      description: 'Restaurant Dubrovnik terrace full with guests waiting for their orders, talking and enjoying',
    },
    {
      src: Galerija15,
      title: 'Restaurant Dubrovnik waiter poruing red wine to two table guests',
      description: 'Restaurant Dubrovnik waiter poruing red wine to two table guests',
    },
    {
      src: Galerija16,
      title: 'Restaurant Dubrovnik terrace view of two seating tables',
      description: 'Restaurant Dubrovnik Food',
    },
    {
      src: Galerija17,
      title: 'Restaurant Dubrovnik table for four pax.',
      description: 'Restaurant Dubrovnik table for four pax.',
    },
    {
      src: Galerija18,
      title: 'Restaurant Dubrovnik table for four pax with reserved sign on the table - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik table for four pax with reserved sign on the table - Restoran Dubrovnik',
    },
    {
      src: Galerija20,
      title: 'Restaurant Dubrovnik glass award for restaurant of the year 2018 - unforgettable croatia',
      description: 'Restaurant Dubrovnik glass award for restaurant of the year 2018 - unforgettable croatia',
    },
    {
      src: Galerija24,
      title: 'Restaurant Dubrovnik - fine dining - big table for eight people full with plates, custom made napkins and wine glasses',
      description: 'Restaurant Dubrovnik big table for eight people full with plates, custom made napkins and wine glasses',
    },
    {
      src: Galerija25,
      title: 'Restaurant Dubrovnik big table for eight people full with plates, custom made napkins and wine glasses',
      description: 'Restaurant Dubrovnik big table for eight people full with plates, custom made napkins and wine glasses',
    },
    {
      src: Galerija26,
      title: 'Restaurant Dubrovnik terrace full of guests at night',
      description: 'Restaurant Dubrovnik terrace full of guests at night',
    },
  ]