import React, {useRef} from 'react'
import HeaderComponent from '../components/HeaderComponent'
import MenuComponent from '../components/MenuComponent'
import {menu} from '../data'
import transition from '../transition'
import { motion, useInView } from 'framer-motion';

const Menu = () => {
    const {menuItems} = menu;
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    return (
        <> 
            <HeaderComponent translate="translateX(100%)" heading="Our menu"/>
            <section className='relative flex flex-col pt-5'>
                <motion.div 
                ref={ref} 
                style={{
                    transform: isInView ? "none" : "translateX(-100%)",
                    opacity: isInView ? 1 : 0,
                    transition: "all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 1.3s"
                  }}
                className='flex justify-center my-3'>
                    <h1 className='font-tangerine text-4xl text-dark'>Menu by Chef Dalibor Vidovic</h1>
                </motion.div>
                {menuItems.map((item, index) => {
                    const {heading, price, items, price2, price3} = item;
                    return <div key={index}><MenuComponent keys={index} heading={heading} price={price} items={items} price2={price2} price3={price3}/></div>
                })}
            </section>
        </>
    )
}

export default transition(Menu);
