import wineList1 from './assets/img/wineList/Restoran Dubrovnik_vinska-1.jpg'
import wineList2 from './assets/img/wineList/Restoran Dubrovnik_vinska-2.jpg'
import wineList3 from './assets/img/wineList/Restoran Dubrovnik_vinska-3.jpg'
import wineList4 from './assets/img/wineList/Restoran Dubrovnik_vinska-4.jpg'
import wineList5 from './assets/img/wineList/Restoran Dubrovnik_vinska-5.jpg'
import wineList6 from './assets/img/wineList/Restoran Dubrovnik_vinska-6.jpg'
import wineList7 from './assets/img/wineList/Restoran Dubrovnik_vinska-7.jpg'
import wineList8 from './assets/img/wineList/Restoran Dubrovnik_vinska-8.jpg'
import wineList9 from './assets/img/wineList/Restoran Dubrovnik_vinska-9.jpg'
import wineList10 from './assets/img/wineList/Restoran Dubrovnik_vinska-10.jpg'

export const wineListSlike = [
    {
      src: wineList1,
      title: 'Restaurant Dubrovnik Wine List',
      description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList2,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList3,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList4,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList5,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList6,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList7,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList8,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList9,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
    {
     src: wineList10,
     title: 'Restaurant Dubrovnik Wine List',
     description: 'Restaurant Dubrovnik wine list- only the best custom selected wines and champagnes',
    },
]

