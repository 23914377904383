import Galerija1 from './assets/img/galleryCatering/restoran-dubrovnik-catering-1.jpg'
import Galerija2 from './assets/img/galleryCatering/restoran-dubrovnik-catering-2.jpg'
import Galerija3 from './assets/img/galleryCatering/restoran-dubrovnik-catering-3.jpg'
import Galerija4 from './assets/img/galleryCatering/restoran-dubrovnik-catering-4.jpg'
import Galerija5 from './assets/img/galleryCatering/restoran-dubrovnik-catering-5.jpg'
import Galerija6 from './assets/img/galleryCatering/restoran-dubrovnik-catering-6.jpg'
import Galerija7 from './assets/img/galleryCatering/restoran-dubrovnik-catering-7.jpg'
import Galerija8 from './assets/img/galleryCatering/restoran-dubrovnik-catering-8.jpg'
import Galerija9 from './assets/img/galleryCatering/restoran-dubrovnik-catering-9.jpg'
import Galerija10 from './assets/img/galleryCatering/restoran-dubrovnik-catering-10.jpg'
import Galerija11 from './assets/img/galleryCatering/restoran-dubrovnik-catering-11.jpg'
import Galerija12 from './assets/img/galleryCatering/restoran-dubrovnik-catering-12.jpg'
import Galerija13 from './assets/img/galleryCatering/restoran-dubrovnik-catering-13.jpg'
import Galerija14 from './assets/img/galleryCatering/restoran-dubrovnik-catering-14.jpg'
import Galerija15 from './assets/img/galleryCatering/restoran-dubrovnik-catering-15.jpg'
import Galerija16 from './assets/img/galleryCatering/restoran-dubrovnik-catering-16.jpg'


export const slikeCatering = [
    {
      src: Galerija1,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija2,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija3,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija4,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija5,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija6,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija7,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija8,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija9,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija10,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija11,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija12,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija13,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija14,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija15,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
    {
      src: Galerija16,
      title: 'Restaurant Dubrovnik Catering, Weddings',
      description: 'Restaurant Dubrovnik Catering, Weddings',
    },
  ]