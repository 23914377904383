import React from 'react'
import Warning_icon from '../assets/img/alert-icon.png';

const ClosedMonths = () => {
  return (
    <>
        <section className='flex flex-col justify-center m-5'>
            <div className='bg-white/70 justify-evenly items-center mx-auto max-w-[600px] flex flex-row rounded-xl border-2 p-3 border-red-700/70 border-solid gap-x-3'>
                <div className='flex justify-center mx-2'>
                    <img src={Warning_icon} alt="" className='w-[300px] lg:w-[180px]'/>
                </div>
                <p className='font-garamond text-md text-center italic text-dark'>Restaurant Dubrovnik will be closed from November 2024 until April 2025. We appreciate your understanding and look forward to welcoming you back in the spring!</p>
            </div>
        </section>
    </>
  )
}

export default ClosedMonths
