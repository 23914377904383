import menuOption1 from './assets/img/menuOptions/Chefs offer_001.png'
import menuOption2 from './assets/img/menuOptions/Chefs offer_002.png'

export const menuChoiceSlike = [
    {
      src: menuOption1,
      title: 'Restaurant Dubrovnik Food - Menu - Fish option, Meat option',
      description: 'Restaurant Dubrovnik Food',
    },
    {
     src: menuOption2,
     title: 'Restaurant Dubrovnik Food - Menu - Vegetarion option',
     description: 'Restaurant Dubrovnik Food - Menu - Vegetarion option',
    },
]

