import menuOption1 from './assets/img/menuOptions/Chefs offer_001.png'

export const menuChoiceSlikaGlavna = [
    {
      src: menuOption1,
      title: 'Restaurant Dubrovnik Food - Menu for bigger groups',
      description: 'Restaurant Dubrovnik Food - Menu for bigger groups',
    }
]

