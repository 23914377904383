import Galerija1 from './assets/img/team/RD_Ivan_Dado.jpg'
import Galerija2 from './assets/img/team/RD_Kuhinja_Team.jpg'
import Galerija3 from './assets/img/team/RD_Kuhinja_Team_2.jpg'
import Galerija4 from './assets/img/team/RD_Dado_Ivo.jpg'
import Galerija5 from './assets/img/team/RD_slika4.jpg'

export const slike = [
    {
      src: Galerija1,
      title: 'Restaurant Dubrovnik Team, Chef Dado and Sous Chef Ivan on main terrace with Restoran Dubrovnik logo behind them',
      description: 'Restaurant Dubrovnik Team, Chef Dado and Sous Chef Ivan',
    },
    {
      src: Galerija2,
      title: 'Restaurant Dubrovnik Team, kitchen chefs together with Sous Chef Ivan',
      description: 'Restaurant Dubrovnik Team, kitchen chefs together with Sous Chef Ivan',
    },
    {
      src: Galerija3,
      title: 'Restaurant Dubrovnik Team in kitchen, Restoran Dubrovnik tim u kuhinji',
      description: 'Restaurant Dubrovnik Team, Restoran Dubrovnik tim u kuhinji',
    },
    {
      src: Galerija5,
      title: 'Restaurant Dubrovnik waiter in white shirt - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik waiter in white shirt - Restoran Dubrovnik',
    },
    {
      src: Galerija4,
      title: 'Restaurant Dubrovnik - Chef Dado and waiter Ivo together',
      description: 'Restaurant Dubrovnik - Chef Dado and waiter Ivo together',
    },
  ]