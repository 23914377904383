import wineList1 from './assets/img/wineList/Restoran Dubrovnik_vinska-1.jpg'

export const wineListGlavnaSlika = [
    {
      src: wineList1,
      title: 'Restaurant Dubrovnik Wine List',
      description: 'Restaurant Dubrovnik Wine List - only best and custom selected wines from all around the world.',
    }
]

