import Galerija1 from './assets/img/about/RD_slika1.jpg'
import Galerija2 from './assets/img/about/RD_slika2.jpg'
import Galerija3 from './assets/img/about/RD_slika3.jpg'
import Galerija5 from './assets/img/about/RD_slika5.jpg'
import Galerija6 from './assets/img/about/RD_slika6.jpg'
import Galerija7 from './assets/img/about/RD_slika7.jpg'
import Galerija8 from './assets/img/about/RD_slika8.jpg'
import Galerija9 from './assets/img/about/RD_slika9.jpg'

export const slike = [
    {
      src: Galerija1,
      title: 'Restaurant Dubrovnik main terrace with chairs and plates - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik main terrace with chairs and plates - Restoran Dubrovnik',
    },
    {
      src: Galerija2,
      title: 'Restaurant Dubrovnik main terrace with chairs and plates - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik main terrace with chairs and plates and glasses - Restoran Dubrovnik',
    },
    {
      src: Galerija3,
      title: 'Restaurant Dubrovnik award Michelin 2019 - Restoran Dubrovnik nagrada Michelin 2019',
      description: 'Restaurant Dubrovnik award Michelin 2019 - Restoran Dubrovnik nagrada Michelin 2019',
    },
    {
      src: Galerija6,
      title: 'Restaurant Dubrovnik two prestige awards - Gault Millau and Michelin plate',
      description: 'Restaurant Dubrovnik two prestige awards - Gault Millau and Michelin plater',
    },
    {
      src: Galerija7,
      title: 'Restaurant Dubrovnik Michelin plate award in 2020 - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik Michelin plate award in 2020',
    },
    {
      src: Galerija8,
      title: 'Restaurant Dubrovnik Michelin plate award in 2021 - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik Michelin plate award in 2021 - Restoran Dubrovnik',
    },
    {
      src: Galerija5,
      title: 'Restaurant Dubrovnik wine cellare with wine bottles - Restoran Dubrovnik',
      description: 'Restaurant Dubrovnik wine cellare with wine bottles - Restoran Dubrovnik',
    },
    {
      src: Galerija9,
      title: 'Restaurant Dubrovnik wine cellar with wine bottles on wooden shelves',
      description: 'Restaurant Dubrovnik wine cellar with wine bottles on wooden shelves - Restoran Dubrovnik vinski podrum s raznim izborom vina',
    },
  ]